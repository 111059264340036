import React, {useEffect, useState} from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import ListBrand from '../../../components/list-brand';
import ListLink from '../../../components/list-link';
import Image from '../../../components/Image';

const KnowledgePage = () => {
  const [pageList, setPageList] = useState('');
  // Ajax設定
  const configJson = {
    method: 'get',
    url: '/update/knowledge/list.json',
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  // Ajax実行
  const dataFetch = async () => {
    axios(configJson)
    .then((response) => {
      setPageList(response.data.list);
    })
    .catch(() => {
      // console.log(error);
    });
  };
  // Event
  useEffect(() => {
    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
      <Layout page="knowledge list-page">
        <Seo title="KNOWLEDGE"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="main-title"><span>KNOWLEDGE</span></h2>
        </section>
        <section className="page-content full-layout">
          <div className="main-content">
            <div className="content-block">
              <h3 className="sub-title"><span>プロが贈る薪ストーブの知恵袋</span></h3>
            </div>
            <div className="content-block">
              <ul>
                {pageList
                    ? pageList.map((list) => (
                        <li key={list.title}>
                          <Link to={`/beginner/knowledge/detail/?id=${list.link}`} className="text-link max">
                            <div className="flex items-center">{list.new ? <p className="new mr-4">NEW</p> : ''}<p>{list.title}</p></div>
                          </Link>
                        </li>
                    ))
                    : null}
              </ul>
            </div>
          </div>
        </section>
        <section className="bread">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>プロが贈る薪ストーブの知恵袋</li>
          </ul>
        </section>
      </Layout>
  );
};

export default KnowledgePage;
